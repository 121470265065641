
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MNavItem from "@/components/MNavItem.vue";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import { SchoolConfig } from "@/entities/school";

export type PageType =
  | "home"
  | "student"
  | "notification"
  | "submission"
  | "room"
  | "staff"
  | "classroom"
  | "school"
  | "mini_app"
  | "other";

@Options({
  components: {
    MIcon,
    MNavItem
  }
})
export default class MNavBar extends Vue {
  showMenu = false;

  get current(): PageType {
    const studentPageNames = [
      "student_list",
      "student_profile",
      "student_history",
      "student_learning",
      "student_badge",
      "student_submissions",
      "group_list",
      "message_list"
    ];
    const notificationPageNames = ["notification"];
    const submissionPageNames = [
      "submission_master_list",
      "submission_master_detail"
    ];
    const staffPageNames = ["staff_list"];
    const classroomPageNames = ["classroom_list"];
    const roomPageNames = [
      "room_setting",
      "room_reservation_list",
      "room_student_list"
    ];
    const schoolPageNames = ["school_setting"];
    const miniappPageNames = ["mini_app_setting"];
    const homePageNames = ["userhome"];

    const currentName = this.$route.name;
    if (typeof currentName !== "string") {
      return "other";
    } else if (homePageNames.includes(currentName)) {
      return "home";
    } else if (studentPageNames.includes(currentName)) {
      return "student";
    } else if (notificationPageNames.includes(currentName)) {
      return "notification";
    } else if (submissionPageNames.includes(currentName)) {
      return "submission";
    } else if (staffPageNames.includes(currentName)) {
      return "staff";
    } else if (classroomPageNames.includes(currentName)) {
      return "classroom";
    } else if (roomPageNames.includes(currentName)) {
      return "room";
    } else if (schoolPageNames.includes(currentName)) {
      return "school";
    } else if (miniappPageNames.includes(currentName)) {
      return "mini_app";
    } else {
      return "other";
    }
  }

  get schoolAIManagementConsoleUrl() {
    let queryParam = "";
    if (store.state.tutor && store.state.school) {
      const userId = store.state.tutor.main.data.id;
      const schoolId = store.state.school.ref.id;
      queryParam = `?school_id=${schoolId}&muid=${userId}`;
    } else if (this.isAdmin) {
      const userId = store.state.admin!.ref.id;
      queryParam = `?role=admin&muid=${userId}`;
    }
    if (window.location.hostname === "admin.mingaku.net") {
      // PROD
      return `https://school-ai-system.web.app/management${queryParam}`;
    } else if (window.location.hostname === "localhost") {
      // DEV
      return `http://localhost:3000/management${queryParam}`;
    } else {
      // STG
      return `https://school-ai-system-stg.web.app/management${queryParam}`;
    }
  }

  @State("schoolConfig") schoolConfig?: SchoolConfig;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isCramSchool") isCramSchool!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;
  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;

  get dynamicCss() {
    if (this.showMenu) {
      return {
        "menu-show": true
      };
    } else {
      return {};
    }
  }
}
