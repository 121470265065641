
import MIcon from "@/components/MIcon.vue";
import { PageType } from "./MNavBar.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  props: {
    text: String,
    icon: String,
    link: String,
    notifications: String,
    selected: Boolean
  }
})
export default class MNavItem extends Vue {
  text!: string;
  icon!: PageType;
  link!: string;
  notifications!: string;
  selected!: boolean;

  get dynamicCss() {
    if (this.selected) {
      return {
        "text-primary-500": true
      };
    } else {
      return {
        "text-gray-500": true
      };
    }
  }
}
