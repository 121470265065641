
import { Options, Vue } from "vue-class-component";
import store from "../store";
import MIcon from "@/components/MIcon.vue";
import { getCustomerPortalSessionUrl } from "@/api/stripe";
import { Getter, State } from "@/store/helper";
import { saveErrorLog } from "@/api/error";
import { Admin } from "@/entities/admin";
import { School, SchoolConfig } from "@/entities/school";
import { TutorMeta } from "@/api/tutor";

export type Breadcrumb = {
  text: string;
  link: string;
};

export type TabItem = {
  text: string;
  link: string;
};

@Options({
  components: {
    MIcon
  },
  props: {
    title: String,
    breadcrumbs: Array,
    tabs: Array,
    currentTabIndex: Number
  }
})
export default class MHeaderPanel extends Vue {
  title!: string;
  breadcrumbs!: Breadcrumb[];
  tabs: TabItem[] = [];
  currentTabIndex = -1;

  @State("admin") admin!: Admin | null;
  @State("tutor") tutor!: TutorMeta | null;
  @State("school") school!: School | null;
  @State("schoolConfig") schoolConfig!: SchoolConfig | null;

  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;

  get username(): string {
    if (this.admin) {
      return `管理人 | ${this.admin.name}`;
    } else if (this.tutor) {
      if (this.school) {
        return `${this.school.data.id} ${this.school.data.name} | ${this.tutor.main.data.name}`;
      } else {
        return this.tutor.main.data.name;
      }
    } else {
      return "";
    }
  }

  get accessiblePaymentInfo(): boolean {
    if (!this.isAdminOfSchool || !this.schoolConfig) {
      return false;
    }

    if (this.schoolConfig.data.paymentType === "stripe") {
      return true;
    } else {
      return false;
    }
  }

  openUserModal() {
    store.commit("SET_IS_OPEN_USER_MODAL", true);
  }

  logout() {
    store.dispatch("signOut");
  }

  async redirectCustomerPortal() {
    if (!store.state.school) {
      return;
    }

    store.commit("START_LOADING", "");
    try {
      const url = await getCustomerPortalSessionUrl(
        store.state.school.ref.id,
        `https://admin.mingaku.net${this.$route.path}`
      );
      store.commit("END_LOADING");
      window.location.href = url;
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get customer portal session url"
      );
    }
  }
}
