import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_icon = _resolveComponent("m-icon")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createBlock("li", {
    class: ["mb-4", _ctx.dynamicCss]
  }, [
    _createVNode(_component_router_link, {
      class: "flex items-center transition-all duration-300 hover:text-primary-500",
      to: _ctx.link
    }, {
      default: _withCtx(() => [
        _createVNode(_component_m_icon, {
          type: _ctx.icon,
          class: "mr-2"
        }, null, 8, ["type"]),
        _createVNode("span", null, _toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ], 2))
}